import { template as template_337c104389f04f2f9357c3261036f1c5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_337c104389f04f2f9357c3261036f1c5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
