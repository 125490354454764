import { template as template_fdcbb8ffcc244ab9a91267f7a97ca67e } from "@ember/template-compiler";
const FKLabel = template_fdcbb8ffcc244ab9a91267f7a97ca67e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
